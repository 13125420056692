import React from 'react';

import TestimonialCard from './TestimonialCard/TestimonialCard';
import ScrollAnimation from 'react-animate-on-scroll';


import classes from './Testimonials.module.scss';


const Testimonials = ({ section }) => {
    return (
        <div className='container'>
            <div className={classes['wrapper']}>
                <ScrollAnimation animateIn="fadeInUp" duration={1}>
                    <h3>Success Stories from Our Learners</h3>
                    <h5>
                        Discover inspiring stories of success from our community. See how learners are advancing
                        their careers and achieving their professional aspirations in the pharmaceutical and biotech
                        industries.
                    </h5>
                </ScrollAnimation>
                <div className="row d-none d-xl-flex">
                    <div className="col-xl-4 ">
                        <TestimonialCard data={section.results[0]} />
                        <TestimonialCard data={section.results[3]} />
                        <TestimonialCard data={section.results[6]} />
                    </div>
                    <div className="col-xl-4 ">
                        <TestimonialCard data={section.results[1]} />
                        <TestimonialCard data={section.results[4]} />
                        <TestimonialCard data={section.results[7]} />
                    </div>
                    <div className="col-xl-4 ">
                        <TestimonialCard data={section.results[2]} />
                        <TestimonialCard data={section.results[5]} />
                        <TestimonialCard data={section.results[8]} />
                    </div>
                </div>
                <div className="row d-none d-lg-flex d-xl-none">
                    <div className="col-lg-6 ">
                        <TestimonialCard data={section.results[0]} />
                        <TestimonialCard data={section.results[2]} />
                        <TestimonialCard data={section.results[4]} />
                        <TestimonialCard data={section.results[6]} />
                        <TestimonialCard data={section.results[8]} />
                    </div>
                    <div className="col-lg-6 ">
                        <TestimonialCard data={section.results[1]} />
                        <TestimonialCard data={section.results[3]} />
                        <TestimonialCard data={section.results[5]} />
                        <TestimonialCard data={section.results[7]} />
                    </div>
                </div>
                <div className="row d-flex d-lg-none">
                    <div className="col-lg-12">
                        <TestimonialCard data={section.results[0]} />
                        <TestimonialCard data={section.results[1]} />
                        <TestimonialCard data={section.results[2]} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;