// import { useEffect } from 'react';
// import { useRouter } from 'next/router';

import Category from './sections/Category/Category';
import Certificate from './sections/Certificate/Certificate';
import Content from './sections/Content/Content';
import Course from './sections/Courses/Course';
import Lecture from './sections/Lectures/Lecture';
import Carousel from './sections/Carousel/Carousel';
import Newsletter from './sections/Newsletter/Newsletter';
import Applications from './sections/Applications/Applications';
import Instagram from './sections/Instagram/Instagram';
import Feedback from './sections/Feedback/Feedback';
import OurPartners from './sections/OurPartners/OurPartners';
import JoinPharmuni from './sections/JoinPharmuni/JoinPharmuni';
import ComingSoon from './sections/ComingSoon/ComingSoon';
import UniversityPartners from './sections/UniversityPartners/UniversityPartners';
import LatestJobPosts from './sections/LatestJobPosts/LatestJobPosts';
import LearnersBusinessesUniversities from './sections/LearnersBusinessesUniversities/LearnersBusinessesUniversities';
import LearnAndGrow from './sections/LearnAndGrow/LearnAndGrow';
import GoogleReviews from './sections/GoogleReviews/GoogleReviews';
import Hero from './sections/Hero/Hero';
import SkillTree from './sections/SkillTree/SkillTree';
import LevelUp from './sections/LevelUp/LevelUp';
import Testimonials from './sections/Testimonials/Testimonials';
import LecturesWithCategory from './sections/LecturesWithCategory/LecturesWithCategory';

import classes from './Parser.module.scss';
import DataCounter from './sections/DataCounter/DataCounter';
import PromotionBanner from '../promotionbanner/PromotionBanner';




const types = {
	CONTENT_TYPES: {
		CAROUSEL: 'carousel',
		CONTENT: 'content',
		NEWSLETTER: 'newsletter',
		CERTIFICATE: 'certificate',
		COURSE: 'course',
		LECTURE: 'lecture',
		CATEGORY: 'category',
		PHARMUNI_PRODUCT: 'pharmuni-product',
		PHARMUNI_ROADMAP: 'pharmuni-roadmap',
		INSTAGRAM_POSTS: 'instagram-posts',
		ABOUT_US: 'about-us',
		TOP_FEEDBACK: 'top-feedback',
		OUR_PARTNERS: 'our-university-partners',
		JOIN_PHARMUNI: 'join-pharmuni',
		COMING_FEATURE: 'coming-feature',
		OUR_UNIVERSITY_PARTNERS_LOGOS: 'our-university-partners-logos',
		LATEST_JOB_POSTS: 'job-post',
		FOR_LEARNERS_BUSINESSES_UNIVERSITIES: 'for_learners_businesses_universities',
		LEARN_AND_GROW: 'learn_and_grow',
		GOOGLE_REVIEWS: 'google_reviews',
		HERO_BANNER: 'hero-banner',
		SKILL_TREE_BANNER: 'skill-tree-banner',
		LEVEL_UP_BENTO_BOX: 'level-up-bento-box',
		TESTIMONIALS_BENTO_BOX: 'testimonials-bento-box',
		LECTURE_WITH_CATEGORY: 'lecture-with-category',
		Statistical_Data: 'statistical-data',
		Subscription_Promotion: 'subscription-promotion'
	}
};

const Parser = ({ data, origin }) => {
	// const loc = useLocation();
	// const { asPath } = useRouter();

	// useEffect(() => {
	// 	const hash = asPath.split('#')[1];
	// 	setTimeout(() => {
	// 		if (hash) {
	// 			let targetNode = document.getElementById(hash.substring(1));
	// 			targetNode?.scrollIntoView(true);
	// 		}
	// 	}, 500);
	// }, [asPath]);
	// console.log('data:', data);

	const UIParser = () => {

		const items = data.map((section, index) => {
			switch (section.type) {
				case types.CONTENT_TYPES.COURSE:
					return <Course key={index} origin={origin} section={section} />;
				case types.CONTENT_TYPES.LECTURE:
					return <Lecture key={index} origin={origin} section={section} />;
				case types.CONTENT_TYPES.NEWSLETTER:
					return <Newsletter key={index} section={section} />;
				case types.CONTENT_TYPES.CONTENT:
					return <Content key={index} origin={origin} section={section} />;
				case types.CONTENT_TYPES.CERTIFICATE:
					return <Certificate key={index} section={section} origin={origin} />;
				case types.CONTENT_TYPES.CATEGORY:
					return <Category key={index} section={section} origin={origin} />;
				case types.CONTENT_TYPES.CAROUSEL:
					return <Carousel key={index} section={section} />;
				case types.CONTENT_TYPES.PHARMUNI_PRODUCT:
					return <Applications key={index} />;
				case types.CONTENT_TYPES.INSTAGRAM_POSTS:
					return <Instagram section={section} key={index} />;
				case types.CONTENT_TYPES.TOP_FEEDBACK:
					return <Feedback section={section} key={index} />;
				case types.CONTENT_TYPES.OUR_PARTNERS:
					return <OurPartners section={section} key={index} />;
				case types.CONTENT_TYPES.JOIN_PHARMUNI:
					return <JoinPharmuni section={section} key={index} />;
				case types.CONTENT_TYPES.COMING_FEATURE:
					return <ComingSoon section={section} key={index} />;
				case types.CONTENT_TYPES.OUR_UNIVERSITY_PARTNERS_LOGOS:
					return <UniversityPartners key={section.type} />;
				case types.CONTENT_TYPES.LATEST_JOB_POSTS:
					return <LatestJobPosts section={section} key={section.type} />;
				case types.CONTENT_TYPES.FOR_LEARNERS_BUSINESSES_UNIVERSITIES:
					return <LearnersBusinessesUniversities key={section.type} />;
				case types.CONTENT_TYPES.LEARN_AND_GROW:
					return <LearnAndGrow key={section.type} />;
				case types.CONTENT_TYPES.GOOGLE_REVIEWS:
					return <GoogleReviews section={section} key={section.type} />;
				case types.CONTENT_TYPES.HERO_BANNER:
					return <Hero key={index} />
				case types.CONTENT_TYPES.SKILL_TREE_BANNER:
					return <SkillTree key={index} />
				case types.CONTENT_TYPES.LEVEL_UP_BENTO_BOX:
					return <LevelUp key={index} />
				case types.CONTENT_TYPES.TESTIMONIALS_BENTO_BOX:
					return <Testimonials key={index} section={section} />
				case types.CONTENT_TYPES.Statistical_Data:
					return <DataCounter key={index} data={section.results} />
				case types.CONTENT_TYPES.LECTURE_WITH_CATEGORY:
					return <LecturesWithCategory key={index} section={section} />
				case types.CONTENT_TYPES.Subscription_Promotion:
					return <PromotionBanner key={index} data={section.results} />

			}
		});
		return items;
	};

	return <main className={`${classes['main-contents']}`}>{UIParser()}</main>;
};

export default Parser;
